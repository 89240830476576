

<template>
  <div id="banner">
    <img
      v-if="bannerImage != undefined || shouldHaveBanner"
      id="banner-image"
      :src="`${bannerImage}`"
    />
    <img v-else id="banner-image" :src="`${server_url}/${randomImage}`" alt />
    <div
      :class="{ 'solo-logo': !showNavigation && $screen.width > 1285, 'with-store': showNavigation }"
      id="logos"
    >
      <a id="link-to-landing" v-if="!isApplication" href="/">
        <img
          v-if="showDarkBrand"
          :class="{ 'scale-logo': !showNavigation && $screen.width > 1285, 'shrink-logo': showNavigation }"
          id="logo"
          src="../assets/Full_logo-Black.svg"
          alt
        />
        <img
          v-else
          :class="{ 'scale-logo': !showNavigation && $screen.width > 1285, 'shrink-logo': showNavigation }"
          id="logo"
          src="../assets/Full_logo.svg"
          alt
        />
      </a>
      <div v-if="showNavigation" id="greater-container">
        <h2 id="greater-than">></h2>
      </div>
      <div v-if="showNavigation" id="image-container">
        <img @click="goToMenu" id="store-image" :src="`${storeImage}`" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhiteHeader",
  components: {},
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    server_url() {
      return process.env.VUE_APP_SERVER_URL;
    },

    randomImage() {
      let imageId = Math.floor(Math.random() * 16) + 1;
      return `files/downloadFile/${this.images[imageId]}`;
    },

    shouldHaveBanner() {
      let routeName = this.$router.currentRoute.name;
      return routeName.includes("menu") || routeName.includes("cart");
    },

    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let darkProfileScreen = routeName.includes("profile") && this.$route.query && this.$route.query.brand == 'dark'
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("open-tables") || routeName.includes("open-rooms") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin") || darkProfileScreen;
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  data() {
    return {};
  },
  props: ["showNavigation", "storeImage", "bannerImage", "isApplication"],
  methods: {
    goToMainScreen: function () {
      this.$router.push({ name: "landing" });
    },

    goToMenu: function () {
      this.$router.push({ name: "menu", params: { tableId: this.tableId } });
    },
  },
};
</script>

<style scoped>
#banner {
  width: 100vw;
  height: 40vh;
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
}

#banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#logos {
  position: absolute;
  top: 20px;
  left: 80px;
  background: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  padding: 0px 16px 0px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1285px) {
  #logos {
    min-width: 95px !important;
    justify-content: flex-start;
    left: 20px;
  }
}

.with-store {
  padding: 1.5px 10px !important;
}
@media screen and (max-width: 1285px) {
  .with-store {
    padding: 0px 10px !important;
  }
}

#link-to-landing {
  text-decoration: none;
}

#logo {
  height: 33px;
  cursor: pointer;
}
@media screen and (max-width: 1285px) {
  #logo {
    height: 33px;
  }
}

.scale-logo {
  height: 40px !important;
  transform: unset !important;
}

#image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#store-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100%;
  border: 0.5px solid #198b4a;
  cursor: pointer;
}
@media only screen and (max-width: 1285px) {
  #store-image {
    width: 27px !important;
    height: 27px !important;
  }
}
@media only screen and (max-width: 370px) {
  #store-image {
    width: 26px !important;
    height: 26px !important;
  }
}
@media only screen and (max-width: 290px) {
  #store-image {
    width: 24px !important;
    height: 24px !important;
  }
}

#greater-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  margin: 0 10px 0 10px;
}
@media only screen and (max-width: 1285px) {
  #greater-container {
    transform: translateY(-0.5px);
  }
}
@media only screen and (max-width: 370px) {
  #greater-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    margin: 0 7px 0px 7px;
    transform: translateY(-1px);
  }
}

#greater-than {
  font-size: 25px;
  color: white;
  -webkit-text-stroke: 1px #198b4a;
  margin: 0;
}
@media only screen and (max-width: 290px) {
  #greater-than {
    font-size: 22px;
  }
}

#banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1285px) {
  #banner {
    height: 290px !important;
  }
}

@media screen and (max-width: 530px) {
  #banner {
    height: 280px !important;
  }
}

@media screen and (max-width: 350px) {
  #banner {
    height: 250px !important;
  }
}

@media screen and (max-width: 330px) {
  #banner {
    height: 235px !important;
  }
}
</style>